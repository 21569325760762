import Image from 'next/image';
import Breadcrumbs from "@marketsystems/nextjs13-appdir-breadcrumbs";

import Container from 'react-bootstrap/Container';
import Col from 'react-bootstrap/Col';
import Row from 'react-bootstrap/Row';


export const Header = ({ title, imgSrc, imgAlt, className, rootLabel }) => {

  return (
    <header className={className}>
      <Container>
        <Row>
          <Col>
            <h1 className="mb-1">{title}</h1>

            <Breadcrumbs listClassName="breadcrumbs" rootLabel={ rootLabel ? rootLabel : 'Home'} />
          </Col>
        </Row>
      </Container>

      <div className="header-img">
        <Image
          src={imgSrc}
          alt={imgAlt}
          fill={true}
          priority={true}
        />
      </div>
    </header>
  );
};
