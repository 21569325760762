import { useRouter } from "next/router";
import Head from "next/head";

import Layout from "../components/layout";
import { Header } from "../components/Header";

import Col from 'react-bootstrap/Col';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';

import { request } from "graphql-request"

import { GET_MENU_YACHTS,  GET_FOOTER, GET_LATEST_YACHTS } from "../lib/queries";

export async function getStaticProps(context) {
  try{
    const { locale } = context;

    const { yachts } = await request(
      process.env.STRAPI_GRAPHQL_ENDPOINT,
      GET_MENU_YACHTS,
      { locale: locale },
    )
    const { footer } = await request(
      process.env.STRAPI_GRAPHQL_ENDPOINT,
      GET_FOOTER,
      { locale: locale },
    )
    const { yachts: latestYachts} = await request(
      process.env.STRAPI_GRAPHQL_ENDPOINT,
      GET_LATEST_YACHTS,
      { locale: locale },
    )
  
    return {
      props: {
        navbar: yachts,
        footer,
        latestYachts,
      },
      revalidate: 1,
    };
  }catch(e){
    console.log(e)
    return {
      props:{

      }
    };
  }
  
}

const ErrorPage = ({ navbar, footer, latestYachts }) => {
  const router = useRouter();

  return (
    <Layout header={navbar} footer={footer} latestYachts={latestYachts}>
      <Head>
        <title>{router.locale === "es" ? "No se encontró la página" : "Page not found"}</title>
        <meta name="description" content="Page not found" />
      </Head>

      <Header
        title={
          router.locale === "es"
            ? "404 Página no encontrada"
            : "404 Page not found"
        }
        imgSrc="/header.webp"
        imgAlt="Yachts sailing on lake with emerald-like water"
      />
      <section className="space-2">
        <Container>
          <Row>
            <Col>
              <h3>
                {router.locale === "es"
                  ? "404 Página no encontrada"
                  : "404 Page not found"}
              </h3>
            </Col>
          </Row>
        </Container>
      </section>
    </Layout>
  );
};

export default ErrorPage;
